<template>
	<BaseModal :visible="visible" :title="$t('select_language')" @close="emit('close')" size="sm" hide-footer>
		<div class="flex flex-col p-6 space-y-6">
			<div
				v-for="(locale, i) in locales"
				:key="i"
				class="flex items-center px-3 py-2 border-2 rounded-lg cursor-pointer border-slate-300 ripple-bg-white"
				@click="change(locale)"
			>
				<span class="flex-grow text-sm font-bold text-slate-600 me-3">
					{{ locale.name }}
				</span>
				<span class="text-xs font-normal text-slate-500">
					{{ locale.code }}
				</span>
			</div>
		</div>
	</BaseModal>
</template>

<script setup lang="ts">
import type { LocaleObject } from 'vue-i18n-routing';

const app = useNuxtApp()

const emit = defineEmits(["close"])
defineProps<{
	visible?: boolean
}>()

const locales = computed(() => app.$i18n.locales.value as LocaleObject[])

const change = async (locale: LocaleObject) => {
	await app.$i18n.setLocale(locale.code)
	location.reload()
	emit("close")
}
</script>
